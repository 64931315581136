import { Link } from "gatsby"
import React from "react"
import { BsCloud } from "react-icons/bs"
import { FaArrowRight } from "react-icons/fa"
import { GiArchiveResearch, GiWorld } from "react-icons/gi"
import { RoundButton } from "../components/buttons/round"
import Layout from "../components/layout"
import SEO from "../components/seo"

const water = require("../images/water.jpeg")
const bluegradient = require("../images/bluegradient.jpeg")
const turbines = require("../images/turbines.jpeg")
const digitaltwins = require("../images/digitaltwins.jpeg")
const utaustin = require("../images/utaustin.png")
const datacenter = require("../images/3d_datacenter.png")
const monitors = require("../images/monitors.png")
const laptop = require("../images/laptop.png")
const servers = require("../images/servers.png")
const serverrack = require("../images/serverrack.png")
const cancer = require("../images/cancer.png")
const flapstrat = require("../images/flapstrat.png")
const wentor2 = require("../images/wentor2.png")

const IndexPage = () => {

  function truncateTitle(title) {
    const maxLength = 40;
    return title.length > maxLength ? title.substring(0, maxLength) + "..." : title;
  }

  return (
    <Layout>
      <SEO title="Home" description="" lang="en" meta={[]} />
      <div className="hero-wrapper justify-content-end">
        <div className="w-100">
          <h1></h1>
          <h1 className="mb-3" style={{ marginTop: "66px" }}>The future of accelerated AI</h1>
          <h3>built for everyone, everywhere.</h3>

          <div className="d-flex" style={{ maxWidth: "300px" }}>
            <a href="#accelerated-ai-programs">
              <button className="btn btn-square border-white mb-8 mt-4 text-light btn-lg">
                Start Now
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Content Block*/}
      <section className="pb-0">
        <div className="container">
          <h3 className="pt-5 pt-lg-2">
            Flapmax is on a mission to enrich the lives of everyone on the planet
            and make the world more sustainable. People and organizations rely on
            technology from Flapmax for every aspect of the technology landscape,
            from healthcare and education to agriculture and everything under the
            sun.
          </h3>
          <div className="row pb-5 mt-8">
            <div className="d-flex justify-content-center">
              <Link to="/about">
                <button className="btn btn-square btn-lg">Read More</button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Content Block */}
      <section id="accelerated-ai-programs">
        <div className="container">
          <h2>Accelerated AI Platforms</h2>
          <div className="mb-5 d-flex justify-content-between">
            <p>
              Get started with our accelerated AI platforms and deploy powerful,
              sustainable solutions for your application or business.
            </p>
          </div>
          <div className="row">
            <div className="my-3 col-lg-6">
              <div className="card h-100">
                <div
                  className="px-4 py-2 mb-5 d-flex align-items-end"
                  style={{
                    minHeight: "300px",
                    backgroundImage: `url(${turbines})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <h2 className="px-2 text-light bold text-shadow-sm bg-white-faded">
                    Measure
                  </h2>
                </div>
                <p className="mb-4">
                  Measure optimizes AI models and measures their performance
                  against targeted hardware platforms before readying them for
                  deployment
                </p>
                <div className="d-flex justify-content-end">
                  <RoundButton
                    link="/platforms/measure"
                    target="_blank"
                    caption="Go to Measure"
                    btnSize="sm"
                    btnChild={<FaArrowRight />}
                  />
                </div>
              </div>
            </div>
            <div className="my-3 col-lg-6">
              <div className="card h-100">
                <div
                  className="px-4 py-2 mb-5 d-flex align-items-end"
                  style={{
                    minHeight: "300px",
                    backgroundImage: `url(${water})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <h2 className="px-2 text-light bold text-shadow-sm bg-white-faded">
                    Coral Imaging
                  </h2>
                </div>
                <p className="mb-4">
                  Coral Imaging is a privacy-preserving, multi-institutional
                  collaboration platform for 3D medical imaging over high-speed
                  communication networks such as 5G
                </p>
                <div className="d-flex justify-content-end">
                  <RoundButton
                    link="/platforms/coral-imaging"
                    target="_blank"
                    caption="Go to Coral Imaging"
                    btnSize="sm"
                    btnChild={<FaArrowRight />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Content Block */}
      <section>
        <div className="container">
          <h2 className="text-center mb-5">AI Programs Designed for Everyone</h2>

          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div className="p-3">
                <img src={serverrack} alt="" className="img-fluid" />
                <h3 className="bold my-4">Africa</h3>
                <p>
                  Africa is a nation rich of resources and bright talent. Flapmax
                  is creating partnerships and working with Africans to ignite
                  growth in their local economies and tech industry.
                </p>
                <RoundButton
                  className="mt-4"
                  btnSize="sm"
                  caption="Learn more about our work in Africa"
                  link="/africa"
                  target="_blank"
                  captionSmall
                  align="start"
                  btnChild={<FaArrowRight size={12} />}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div className="p-3">
                <img src={servers} alt="" className="img-fluid" />
                <h3 className="bold my-4">Sustainability</h3>
                <p>
                  Flapmax is diving headfirst into solving several issues of
                  sustainability related to climate change, health equity, and
                  workforce empowerment to improve efficiency and reduce
                  pollution.
                </p>
                <RoundButton
                  className="mt-4"
                  btnSize="sm"
                  captionSmall
                  link="https://sustainability.flapmax.com/"
                  target="_blank"
                  align="start"
                  caption="Learn more about our sustainability initiatives"
                  btnChild={<FaArrowRight size={12} />}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div className="p-3">
                <img src={laptop} alt="" className="img-fluid" />
                <h3 className="bold my-4">Developer</h3>
                <p>
                  We are working on a broad array of tools to extend our platform.
                  Including open-source, machine learning, and analytics projects;
                  we want to help create the future of tech.
                </p>
                <RoundButton
                  className="mt-4"
                  btnSize="sm"
                  captionSmall
                  link="https://developer.flapmax.com/"
                  target="_blank"
                  align="start"
                  caption="Explore our developer tools and contribute to open-source projects"
                  btnChild={<FaArrowRight size={12} />}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div className="p-3">
                <img src={monitors} alt="" className="img-fluid" />
                <h3 className="bold my-4">University</h3>
                <p>
                  Partnering with several top class universities around the world,
                  we are working with academics and students to both innovate and
                  foster growth for the next generation.
                </p>
                <RoundButton
                  className="mt-4"
                  btnSize="sm"
                  captionSmall
                  link="https://university.flapmax.com/"
                  target="_blank"
                  align="start"
                  caption="Explore our university programs and academic collaborations"
                  btnChild={<FaArrowRight size={12} />}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Content Block */}
      <section className="bg-gray">
        <div className="container-fluid">
          <h2 className="mb-8 text-center">Flapmax AI Academy</h2>

          <div className="row">
            <div className="my-3 col-lg-12 col-xl-4">
              <div className="d-flex flex-column flex-md-row">
                <div className="my-3 d-flex justify-content-center align-items-start px-sm-5 px-md-5 px-xl-2 px-xxl-5">
                  <BsCloud size={80} />
                </div>
                <div>
                  <h3 className="no-wrap">Training &amp; Apprenticeship</h3>
                  <p className="text-muted" style={{ minWidth: "293px" }}>
                    Acquire new skills in AI and Cloud, learn on-the-job and earn
                    new certifications.
                  </p>
                </div>
              </div>
            </div>

            <div className="my-3 col-lg-12 col-xl-4">
              <div className="d-flex flex-column flex-md-row">
                <div className="my-3 d-flex justify-content-center align-items-start px-sm-5 px-md-5 px-xl-2 px-xxl-5">
                  <GiWorld size={80} />
                </div>
                <div>
                  <h3 className="no-wrap">International Engineer</h3>
                  <p className="text-muted" style={{ minWidth: "293px" }}>
                    Participate in international collaboration with our global
                    academic and industry partners.
                  </p>
                </div>
              </div>
            </div>

            <div className="my-3 col-lg-12 col-xl-4">
              <div className="d-flex flex-column flex-md-row">
                <div className="my-3 d-flex justify-content-center align-items-start px-sm-5 px-md-5 px-xl-2 px-xxl-5">
                  <GiArchiveResearch size={80} />
                </div>
                <div>
                  <h3 className="no-wrap">Resident Researcher</h3>
                  <p className="text-muted">
                    Conduct cutting-edge research with our engineers and publish
                    your work.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-8">
          <RoundButton
            className="mt-4"
            link="/ai-academy"
            caption="See more about Flapmax AI Academy"
            btnChild={<FaArrowRight size={12} />}
          />
        </div>
      </section>

      {/* Content Block */}
      <section>
        <div className="container">
          <h2 className="mb-5">Featured News</h2>
          <div className="row">
            <Link to="https://blog.flapmax.com/articles/helping-keep-hope-alive-for-children-with-cancer-a" className="col-lg-3 col-md-6 text-decoration-none" target="_blank">
              <img src={cancer} alt="" className="img-fluid" />
              <div className="p-3">
                <h4>
                  {truncateTitle("Helping Keep Hope Alive for Children with Cancer")}
                </h4>
                <p className="text-muted no-wrap">February 14th, 2024</p>
              </div>
            </Link>

            <Link to="https://blog.flapmax.com/articles/flapmax-and-wentors-announce-second-cohort-of-women" className="col-lg-3 col-md-6 text-decoration-none" target="_blank">
              <img src={wentor2} alt="" className="img-fluid" />
              <div className="p-3">
                <h4>
                  {truncateTitle("Flapmax and Wentors Announce Second Cohort of Women in AI Mentorship Program")}
                </h4>
                <p className="text-muted no-wrap">April 26th, 2023</p>
              </div>
            </Link>

            <Link to="https://blog.flapmax.com/articles/flapmax-ut-austin-externship-spring-2022" className="col-lg-3 col-md-6 text-decoration-none" target="_blank">
              <img src={utaustin} alt="" className="img-fluid" />
              <div className="p-3">
                <h4>
                  {truncateTitle("Working with UT Austin School of Engineering to Support Students’ Career Decision Making through Externships")}
                </h4>
                <p className="text-muted no-wrap">January 14th, 2022</p>
              </div>
            </Link>

            <Link to="https://blog.flapmax.com/articles/a-10-year-road-map-on-the-future-of-ai-and-flapmax" className="col-lg-3 col-md-6 text-decoration-none" target="_blank">
              <img src={flapstrat} alt="" className="img-fluid" />
              <div className="p-3">
                <h4>
                  {truncateTitle("A 10-year roadmap for the future of accelerated AI for everyone, everywhere")}
                </h4>
                <p className="text-muted no-wrap">October 1st, 2021</p>
              </div>
            </Link>
          </div>
          <div className="d-flex justify-content-center">
            <RoundButton
              caption="See More News"
              btnChild={<FaArrowRight />}
              link="https://blog.flapmax.com"
              target="_blank"
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
