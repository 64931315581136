import { Link } from "gatsby"
import React, { useState } from "react"

interface Props {
  className?: string
  link?: string
  target?: string
  caption?: string
  captionSmall?: boolean
  align?: "end" | "start" | "center"
  btnSize?: "lg" | "sm"
  btnChild: any
}

export const RoundButton = ({
  className,
  link,
  target = "_self",
  caption,
  captionSmall,
  align = "center",
  btnSize,
  btnChild,
}: Props) => {
  const [hovered, setHovered] = useState(false)

  if (link?.startsWith("/")) {
    return (
      <Link
        to={link}
        target={target}
        className={`d-flex align-items-${align} gap-3 text-decoration-none ${className}`}
        style={{ cursor: "pointer" }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {/* Button & Button Child */}
        <button
          className={`btn btn-square rounded 
          ${hovered && "hovered"} 
          ${btnSize === "lg" && "btn-lg"} 
          
          ${btnSize === "sm" && "btn-sm"}
          ratio-1-1`}
        >
          <div className={`d-flex justify-content-center align-items-center`}>
            {btnChild}
          </div>
        </button>
        {/* Caption */}
        {captionSmall ? (
          <h4>{caption}</h4>
        ) : (
          <p className="mb-0 pb-0">{caption}</p>
        )}
      </Link>
    )
  }

  if (link) {
    return (
      <a
        href={link}
        target={target}
        className={`d-flex align-items-${align} text-decoration-none gap-3 ${className}`}
        style={{ cursor: "pointer" }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {/* Button & Button Child */}
        <button
          className={`btn btn-square rounded 
          ${hovered && "hovered"} 
          ${btnSize === "lg" && "btn-lg"} 
          
          ${btnSize === "sm" && "btn-sm"}
          ratio-1-1`}
        >
          <div className={`d-flex justify-content-center align-items-center`}>
            {btnChild}
          </div>
        </button>
        {/* Caption */}
        {captionSmall ? (
          <h4>{caption}</h4>
        ) : (
          <p className="mb-0 pb-0">{caption}</p>
        )}
      </a>
    )
  }

  return (
    <div
      className={`d-flex align-items-${align} gap-3 ${className}`}
      style={{ cursor: "pointer" }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* Button & Button Child */}
      <button
        className={`btn btn-square rounded ${hovered && "hovered"} ${
          btnSize === "lg" && "btn-lg"
        } 
          
          ${btnSize === "sm" && "btn-sm"} ratio-1-1`}
      >
        <div className="d-flex justify-content-center align-items-center">
          {btnChild}
        </div>
      </button>
      {/* Caption */}
      {captionSmall ? (
        <h4>{caption}</h4>
      ) : (
        <p className="mb-0 pb-0">{caption}</p>
      )}
    </div>
  )
}
